import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import cartReducer from '../reducers/cartReducer'
import routerReducer from '../reducers/routerReducer'
import analyticMiddleware from '../middleware/analyticMiddleware'
import notificationReducer from '../reducers/notificationReducer'
import sharedReducer from '../reducers/sharedReducer'
import formReducer from '../reducers/formReducer'
import headerReducer from '../reducers/headerReducer'
import { errorReducer } from '../reducers/errorReducer'
import loginReducer from '../reducers/loginReducer'

const noResultStore = createStore(combineReducers({
  forms: formReducer,
  cart: cartReducer,
  router: routerReducer,
  notification: notificationReducer,
  shared: sharedReducer,
  header: headerReducer,
  errors: errorReducer,
  login: loginReducer
}),
compose(
  applyMiddleware(thunk, analyticMiddleware),
  window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f)
)
export default noResultStore
