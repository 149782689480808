import { AppContainer } from './components/AppContainer/AppContainer'
import noResultsStore from './store/storeNoResults'
import { Header } from './components/_header/Header/Header'
import { NoResultsApp } from './components/_search/NoResultsApp/NoResultsApp'
import { Footer } from './components/Footer/Footer'
import { hydrateRoot } from 'react-dom/client'

hydrateRoot(
  document.getElementById('root'),
  <AppContainer
    store={noResultsStore}
  >
    <Header />
    <NoResultsApp type='404' />
    <Footer />
  </AppContainer>
)
